/* eslint-disable no-unused-vars */
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en'; // locale-data for en

import React, { useEffect, lazy, Suspense, useState } from 'react';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { globalHistory, Router,useLocation } from '@reach/router';

import ChangePassword from './views/ChangePassword';
import ResetPassword from './views/ResetPassword';
import LandingTechnology from './views/LandingTechnology';
import LandingExpenses from './views/LandingExpenses';
import Register from './views/Register';
import RegisterLanding from './views/RegisterLanding';
import Login from './views/Login';
import ConnectBankSuccess from './views/ConnectBankSuccess';
import AppLinkAuth from './views/AppLinkAuth';
import LoginAuthorise from './views/LoginAuthorise';
import Loader from './components/Loader';

import { IS_AUTHENTICATED, GET_META } from './graph/auth';
import { GET_MENU_STATE } from './graph/navigation';


import { isMobileDevice } from './utils';
import useHubspotChat from './hooks/useHubspotChat';
import hubSpotTracking from './hooks/hubSpotTracking';

const AppRoutes = lazy(() => import('./AppRoutes'));
const AccountantAppRoutes = lazy(() => import('./AccountantAppRoutes'));
const SuperUserAppRoutes = lazy(() => import('./SuperUserAppRoutes'));
const BookkeeperAdminAppRoutes = lazy(() =>
  import('./BookkeeperAdminAppRoutes'),
);
const AccountantBookkeeperAppRoutes = lazy(() =>
  import('./AccountantBookkeeperAppRoutes'),
);

const App = () => {
  const client = useApolloClient();

  const { data: authenticatedData } = useQuery(IS_AUTHENTICATED);

  useHubspotChat(process.env.REACT_APP_HUBSPOT_ACCOUNT_ID);

  const [currentPage, setSurrentPage] = useState();

  const { data: menuData } = useQuery(GET_MENU_STATE);

  // Fetched commonly used data
  const { data, loading } = useQuery(GET_META, {
    skip: !authenticatedData?.isAuthenticated,
  });


  if (window.fbq) {
    window.fbq('track', 'PageView');
  }


  // useEffect(() => {
  //   hubSpotTracking.identifyUser(data.user.email, data.user.id);
  // }, [data]);

  // Hook into router change event
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        if (window.fbq) {
          window.fbq('track', 'PageView');
        }

        if (window.firebase && window.firebase.analytics) {
          window.firebase.analytics().logEvent('screen_view');
        }

        if (window.gtag) {
          window.gtag('config', 'process.env.REACT_APP_AW_ID');
          window.gtag('config', 'UA-151517575-1');

          window.gtag('event', 'page_view', {
            page_path: window.location.pathname,
            send_to: 'UA-151517575-1',
            user_id: data?.user?.id,
          });
        }

        if (isMobileDevice()) {
          client.cache.writeQuery({
            query: GET_MENU_STATE,
            data: {
              menuState: { ...(menuData?.menuState || {}), main: false },
            },
          });
        }

        window.scrollTo(0, 0);

        setSurrentPage(window.location.pathname);
      }
    });
  }, []); // eslint-disable-line


  const RouteChangeTracker = () => {
    const location = useLocation();
    useEffect(() => {
      hubSpotTracking.trackPageView();
    }, [location]);
    return null;
  };
  


  if (loading) {
    return <Loader />;
  }

  if (!authenticatedData?.isAuthenticated) {
    return (
      <>
        {/* <RouteChangeTracker /> */}
        <Router>
          {/* Onboarding */}
          <Login default path="/" />
          <RegisterLanding path="/register" />
          <RegisterLanding path="/register/:referralCode/:referrerName" />
          <RegisterLanding path="/register/:referralCode" />
          <Register path="/onboarding" />
          <Register path="/onboarding/:referralCode/:referrerName" />
          <Register path="/onboarding/:referralCode" />
          <LoginAuthorise path="/authorise" />

          {/* Landing Pages */}
          <LandingExpenses path="/expenses" />
          <LandingTechnology path="/self-assessment-app" />

          {/* Onboarding */}
          <ConnectBankSuccess path="/onboarding/connect/success" />

          {/* Reset Password */}
          <ResetPassword path="/reset-password" />
          <ChangePassword path="/change-password/:token" />
          <AppLinkAuth path="/auth/:accessToken/:refreshToken/:redirect" />
        </Router>
      </>
    );
  }

  if (data?.user?.capabilities?.viewSuperUserPortal) {
    return (
      <Suspense fallback={<Loader />}>
        <SuperUserAppRoutes />
      </Suspense>
    );
  }

  if (
    data?.user?.capabilities?.viewAccountantPortal &&
    data?.user?.capabilities?.viewBookkeeperPortal
  ) {
    return (
      <Suspense fallback={<Loader />}>
        <AccountantBookkeeperAppRoutes />
      </Suspense>
    );
  }

  if (data?.user?.capabilities?.viewAccountantPortal) {
    return (
      <Suspense fallback={<Loader />}>
        <AccountantAppRoutes />
      </Suspense>
    );
  }

  if (data?.user?.capabilities?.viewBookkeeperPortal) {
    return (
      <Suspense fallback={<Loader />}>
        <BookkeeperAdminAppRoutes />
      </Suspense>
    );
  }

  if (data?.user?.capabilities?.viewCustomerPortal) {
    return (
      <>
        <Suspense fallback={<Loader />}>
          <AppRoutes {...(data?.user?.capabilities || {})} />
        </Suspense>
      </>
    );
  }

  return false;
};

export default App;
