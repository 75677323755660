/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
import { useEffect, useState, useRef, useCallback } from 'react';

const useHubspotChat = (portalId) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [activeConversation, setActiveConversation] = useState(false);
  const eventRef = useRef(null);

  useEffect(() => {
    window._hsq = window._hsq || [];
    window._hsq.push(['setPortalId', portalId]);
    window.hsConversationsSettings = { portalId };

    const script = document.createElement('script');
    script.src = `//js.hs-scripts.com/${portalId}.js`;
    script.async = true;

    script.onload = () => {
      setHasLoaded(true);
      if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.show();
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [portalId]);

  useEffect(() => {
    const setupEvent = () => {
      if (window.HubSpotConversations) {
        eventRef.current = (payload) => {
          setActiveConversation(payload.conversation.conversationId);
        };
        window.HubSpotConversations.on('conversationStarted', eventRef.current);
      }
    };

    if (hasLoaded) {
      setupEvent();
    }

    return () => {
      if (window.HubSpotConversations && eventRef.current) {
        window.HubSpotConversations.off(
          'conversationStarted',
          eventRef.current,
        );
      }
    };
  }, [hasLoaded]);

  const openHandler = useCallback(() => {
    if (hasLoaded) {
      window.HubSpotConversations.widget.open();
    }
  }, [hasLoaded]);

  const closeHandler = useCallback(() => {
    if (hasLoaded) {
      window.HubSpotConversations.widget.close();
    }
  }, [hasLoaded]);

  return {
    hasLoaded,
    activeConversation,
    openHandler,
    closeHandler,
  };
};

export default useHubspotChat;
